import React, { createContext, useState,useEffect } from 'react';
import axios from "axios";
import getBaseUrl from '../hooks/http'
import { useAuthContext } from '../hooks/useAuthContext';
export const CountContext = createContext();

export const CountProvider = ({ children }) => {
  const [interviewCount, setInterviewCount] = useState(0);
  const [offerCount, setOfferCount] = useState(0);
  const [selectedCandidate,setSelectedCandidate] = useState(0);
  // Function to fetch data using Axios 
    const getInterviewDataCount = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user !== null) {
          let config = {
          headers: { Authorization: `Bearer ${user.token}`, },
       };
  
     
      //  setInterviewCount(res.data.interviewData.length);
      if(user.typ=='cnd'){
        const [response] = await Promise.all([
          axios.get(`${getBaseUrl}/interviews/CandidategetCount`, config)
      ]);
      updateInterviewCount(response.data.interviewCount);
      updateOfferCount(response.data.offerCount);
      updateSelectedCanCount(response.data.selectedCandidateCount);

      }else{
        const [response1] = await Promise.all([
          axios.get(`${getBaseUrl}/interviews/EmployergetCount`, config)
      ]);
      updateInterviewCount(response1.data.interviewCount);
      updateOfferCount(response1.data.offerCount);
      updateSelectedCanCount(response1.data.selectedCandidateCount);
      
    }

      }  
      } catch (error) {
         console.log(error)
      } finally {
         
      }
      }


      
    
 // Function to update interview count
 const updateInterviewCount = (newCount) => {
  setInterviewCount(newCount);
};

 // Function to update offer count
 const updateOfferCount = (newCount) => {
  setOfferCount(newCount);
};

// Function to update selected candidate count
const updateSelectedCanCount = (newCount) => {
  setSelectedCandidate(newCount);
};
  

  return (
    <CountContext.Provider value={{ interviewCount, updateInterviewCount , offerCount, updateOfferCount,selectedCandidate,updateSelectedCanCount ,getInterviewDataCount}}>
      {children}
    </CountContext.Provider>
  );
};