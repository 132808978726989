
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { CountContext } from "../../../context/CountContext.js";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";
import { io } from "socket.io-client";
//import { useLogout } from '../../../hooks/useLogout';



export const useLogin = () => {

   const naviagte = useNavigate()

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const { dispatch } = useAuthContext();
   const { getInterviewDataCount } = useContext(CountContext);

   const handleStatusUpdate = (sessionId, candId) => {
      const url = `${getBaseUrl}`.replace(/\/api/g, '')
      const socket = io.connect(url);
      socket.emit('candidateLogin', { sessionId: sessionId, userId: candId });
      console.log('candidateLogin');
   };

   const login = async (dialingCode, phoneNumber, password, empId) => {

      setError(null)
      setIsLoading(true)

      const regex = /^\d+$/;
      let inputValue = '';

      if (regex.test(phoneNumber)) {
         inputValue = `${dialingCode}${phoneNumber}`.replace(/[+()]/g, "");
      } else {
         inputValue = phoneNumber;
      }


      const body = { inputValue, password }
      axios.post(`${getBaseUrl}/candidates/login`, body)
         .then((res) => {
            console.log(res.data);
            if (res.status === 200) {
               /* update the auth context */
               dispatch({ type: 'LOGIN', payload: res.data })
               /* save the user to local storsge */
               localStorage.setItem('empId', empId)
               localStorage.setItem('CandidateId', res.data.candidateId)
               sessionStorage.setItem("ssid", res.data.sessionId);
               getInterviewDataCount();

               /* rediredt */
               if (res.data.Is_AgreementSign == "No") {
                  naviagte('/candidate/profile/CndAgreement');
               }
               else if (empId !== '' && res.data.mode!=="save" &&  empId.includes("Interview")) {
                  naviagte('/candidate/profile/interview');
               } else if (empId !== '' && empId.includes("Offers")){
                  naviagte('/candidate/profile/offers');
               }else {
                  naviagte('/candidate/profile/interview');
               }
               handleStatusUpdate(res.data.sessionId, res.data.candidateId);
               setIsLoading(true)
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false)
            setError(error?.response?.data?.error);
         })

      // const response = await fetch(`${getBaseUrl}/candidate/login`,{
      //    method:'POST',
      //    headers : { 
      //       'Content-Type': 'application/json',
      //    },
      //    body:JSON.stringify({inputValue,password})
      // })
      // const json = await response.json()

      // if (!response.ok) {
      //    setIsLoading(false)
      //    setError(json.error)
      // } 
      // if (response.ok)  {
      //    //save the user to local storsge
      //    localStorage.setItem('user',JSON.stringify(json))

      //    //update the auth context
      //    dispatch({type:'LOGIN',payload:json})

      //    //rediredt to profile
      //    naviagte('/profile')

      //    setIsLoading(true)
      // }
   }

   return { login, isLoading, error }
}