import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import Datetime from 'react-datetime';
import { Container, Spinner, Modal, Row, Col, Button, Form } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { FaCalendar } from 'react-icons/fa';
import getBaseUrl from '../../../../hooks/http'
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { CountContext } from '../../../../context/CountContext';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { AiOutlineClose } from 'react-icons/ai';
import { TfiEye } from 'react-icons/tfi';
import { useCandidateOnboarding } from './useCandidateOnboarding';
import './selectedCandidate.css';
import { Document, Page, pdfjs } from "react-pdf";
//Import constant array
import { NewBeforeArrival, NewOnArrival, TransferBeforeArrival, TransferOnArrival, ExSingaporeBeforeArrival, ExSingaporeOnArrival } from '../../../../components/Constant/onBoardingConstant'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
const SelectedCandidate = () => {
   const { user } = useAuthContext();
   const { saveStatus } = useCandidateOnboarding()

   const [onboardingData, setOnboardingData] = useState([]);
   const [onboardingHistoryData, setOnboardingHistoryData] = useState([]);
   const { updateSelectedCanCount } = useContext(CountContext)
   // const [stepsData, setStepsData] = useState(null);
   const [step, setStep] = useState(null);
   const [index, setIndex] = useState(null);
   const [modal, setModal] = useState(false);
   const [isFileExists, setIsFileExists] = useState(false);
   const [dataType, setDataType] = useState('currentin-process');
   const [selectedValue, setSelectedValue] = useState(null);
   const [reasonError, setReasonError] = useState(false);

   const [helperData, setHelperData] = useState({
      letter: false,
      refLetter: ''
   })

   // ---------------- react-datetime utils -----------------------------
   // Handle clear button click
   // const handleClear = () => {
   //    // setFormData({ ...formData, birthday: null })
   //    // if (mode === 'update') setFormEditData({ ...formEditData, birthday: null })
   // };
   const renderInput = (props, openCalendar, closeCalendar) => {

      return (
         <div className='d-flex align-items-center arrivalDateWrapper'>
            <input {...props} onClick={openCalendar} className='flex-grow-1 border-0 h-100' />
            <FaCalendar onClick={openCalendar} style={{ cursor: 'pointer' }} />
         </div>
      );
   };
   // ----------------react-datetime End -----------------------------

   useEffect(() => {
      getOnboardingData();
      // check data type and dispaly data
      if (dataType === 'currentin-process') getOnboardingData();
      else getOnboardingHistoryData();
   }, [user, dataType])


   // get Onboarding candidate Data
   const getOnboardingData = async () => {
      try {
         if (user !== null) {
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.get(`${getBaseUrl}/onboarding/`, config)
            console.log('onboarding', res.data);
            updateSelectedCanCount(res.data.onboardingData.length)
            if (res.status === 200) {
               setOnboardingData(res.data.onboardingData);
            }
         }
      } catch (error) {
         console.log(error);
      }
   }

   // get Onboarding  history candidate Data
   const getOnboardingHistoryData = async () => {
      try {
         if (user !== null) {
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.get(`${getBaseUrl}/onboarding/onboarding-history/`, config)
            if (res.status === 200) {
               setOnboardingHistoryData(res.data.onboardingDataHistory)
               try {
                  let config = {
                     headers: { Authorization: `Bearer ${user.token}`, },
                  };
                  const getSelectedCount = await axios.get(`${getBaseUrl}/interviews/EmployergetCount`, config)
                  updateSelectedCanCount(getSelectedCount.data.selectedCandidateCount)
               } catch (e) {

               }
            }
         }
      } catch (error) {
         console.log(error);
      }
   }


   //handle ArrivalDate on change
   const handleArrivalDate = (date, index, step) => {
      const newArr = [...onboardingData];
      newArr[index][`${step}`]['arrivalDate'] = date;
      newArr[index][`${step}`]['arrivalDateError'] = false;
      setOnboardingData(newArr);
   }
   const handleSIPTrainingDate = (date, index, step) => {
      const newArr = [...onboardingData];
      newArr[index][`${step}`]['SIPTrainingDate'] = date;
      newArr[index][`${step}`]['SIPTrainingDateError'] = false;
      setOnboardingData(newArr);
   }
   //handle Cancellation Reason
   const handleCancellationReason = (e, index, step) => {
      const newArr = [...onboardingData];
      console.log(step);
      newArr[index][`${step}`]['cancellationReason'] = e.target.value;
      newArr[index][`${step}`]['cancellationReasonError'] = false;
      setOnboardingData(newArr);
   }
   //handle stattus change event
   const handleStatus = async (e, index, steps) => {
      console.log(e.target.value, index, steps);
      const newArr = [...onboardingData]
      newArr[index][`${steps}`]['value'] = e.target.value;
      newArr[index][`${steps}`]['error'] = false;
      setOnboardingData(newArr);
   }
   //handle Confirm Status btn click event
   const handleConfirmStatus = (e, index) => {
      const step = e.target.getAttribute('data-step'); // get data id (step no)
      setIndex(index);
      setStep(step)
      console.log('step', step);
      //data array 
      const newArr = [...onboardingData];
      //destructor object
      const { beforeArrivalSteps, onArrivalSteps } = newArr[index];
      //validation for beforeArrivalSteps 
      if (step === '1') {
         const { value, arrivalDate, SIPTrainingDate, cancellationReason } = beforeArrivalSteps;
         setSelectedValue(value);
         if (!value) {
            newArr[index]['beforeArrivalSteps']['error'] = true
            setOnboardingData(newArr);
            return;
         }
         if (value === 'ArrivalDateConfirmed' && !arrivalDate) {
            newArr[index]['beforeArrivalSteps']['arrivalDateError'] = true
            setOnboardingData(newArr);
            return;
         }
         if (value === 'SIPTrainingBooked' && !SIPTrainingDate) {
            newArr[index]['beforeArrivalSteps']['SIPTrainingDateError'] = true
            setOnboardingData(newArr);
            return;
         }

      }
      //validation for  onArrivalSteps
      if (step === '2') {
         const { value, cancellationReason } = onArrivalSteps;
         console.log("dsdgahsgda", value);
         setSelectedValue(value);
         if (!value) {
            newArr[index]['onArrivalSteps']['error'] = true
            setOnboardingData(newArr);
            return;
         }
      }

      setModal(true);

   }

   //handle Confirmation ok click event
   const handleConfirmationOk = async (e) => {
      const newArr = [...onboardingData];
      console.log(newArr);
      const { candidate_id, beforeArrivalSteps, onArrivalSteps } = newArr[index];
      if (beforeArrivalSteps.value === 'CandidateSelectionCancelled' && !beforeArrivalSteps.cancellationReason) {
         newArr[index]['beforeArrivalSteps']['cancellationReasonError'] = true
         setReasonError(true);
         setOnboardingData(newArr);
         return;
      }
      if (onArrivalSteps.value === 'CandidateSelectionCancelled' && !onArrivalSteps.cancellationReason) {
         newArr[index]['beforeArrivalSteps']['cancellationReasonError'] = true
         setReasonError(true);
         setOnboardingData(newArr);
         return;
      }
      setModal(false);
      // define data object for api
      const data = {
         cndId: candidate_id,
         EmpId: user.EmpId,
         beforeArrivalSteps: beforeArrivalSteps,
         onArrivalSteps: onArrivalSteps,
      }
      // send data to save status
      await saveStatus(data);
      // set isLoading true
      newArr[index][`isLoading${step}`] = true;
      setOnboardingData(newArr);

      setTimeout(() => {
         // set isLoading false
         newArr[index][`isLoading${step}`] = false;
         setOnboardingData(newArr);
         // run getOnboardingData for updated data
         getOnboardingData();
      }, 1000);
   }

   //View Document Functionality
   const [show, setShow] = useState({
      viewMore: false,
   });

   const [documentModal, setdocumentModal] = useState({
      show: false,
      file: ''
   })
   const [details, setDetails] = useState([])
   const handleViewDetails = async (i) => {


      const list = [...onboardingData];
      list[i]["candidateDetails"][0].photoPreviewUrl = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i]["candidateDetails"][0].photo.data}`;
      const dob = new Date(list[i]["candidateDetails"][0].birthday?.data).toLocaleString('en-GB', {
         year: 'numeric', month: 'short', day: '2-digit',
      }).replace(/ /g, '-')
      list[i]["candidateDetails"][0].dob = dob;


      // console.log(list[i]);
      setDetails(list[i]["candidateDetails"]);
      setShow({ ...show, viewMore: true })

   }
   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };
   const handleClose = (modal) => {
      console.log(modal);
      if (modal === 'viewMoreDetailsModal') {
         setShow({ ...show, viewMore: false });
      } else {
         setShow({ ...show, helper: false });
      }
      setIsFileExists(false);
      setHelperData({ letter: false, refLetter: '' });
   }

   //Handle View Documents
   const [pageNum, setPageNum] = useState(1);
   const [totalPages, setTotalPages] = useState(0);
   const [pageDetails, setPageDetails] = useState(null);

   //pdf -----------------------------------------------
   const pdfPrevPage = () => {
      setPageNum(pageNum - 1)
   }
   const pdfNextPage = () => {
      setPageNum(pageNum + 1)
   }

   const viewDocuments = async (fileName) => {
      try {
         console.log(fileName);
         setdocumentModal({ ...documentModal, show: true, file: `${getBaseUrl}/candidates/getMediaFileFromDb/${fileName}` })
      }
      catch (error) {
         console.log(error);
      }

   }
   const downloadDocuments = async () => {
      if (user !== null) {

         const response = await axios.get(`${getBaseUrl}/agreement/download-pdf/${documentModal.file.split('/').pop()}`, {
            responseType: 'blob', // Important: tells Axios to treat the response as binary data (file)
         });

         // Create a blob from the file data and download it
         const url = window.URL.createObjectURL(new Blob([response.data]));
         // Extract the file name from the URL
         const originalFileName = documentModal.file.split('/').pop().split('?')[0];
         // Get the file extension
         const extension = originalFileName.split('.').pop();
         let fileName = "";
         if (originalFileName.toLowerCase().includes("passport")) {
            fileName = `Passport.${extension}`;
         } else if (originalFileName.toLowerCase().includes("edu")) {
            fileName = `Educational_Ceritificate.${extension}`;
         } else if (originalFileName.toLowerCase().includes("medrep")) {
            fileName = `Medical_Reports.${extension}`
         } else { }
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', fileName); // Set the filename
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link); // Clean up
      }
   }

   const printFile = async () => {
      const baseUrl = `${getBaseUrl}`.replace(/\/api/g, '');
      const fileUrl = `${documentModal.file}`; // Assume this is the URL to your file

      // Get the file extension to determine the type of file
      const fileExtension = fileUrl.split('.').pop().toLowerCase();

      // Open the file in a new tab
      const newTab = window.open(fileUrl, '_blank');

      // Handle different file types for printing
      newTab.onload = () => {
         if (['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'txt'].includes(fileExtension)) {
            // These types can be rendered in the browser and printed
            newTab.print();
         } else {
            // For unsupported types (e.g., Word, Excel), we notify the user that the file can't be printed directly
            alert('This file type cannot be printed directly in the browser. Please download and print it manually.');
         }
      };
   };

   // Function to determine file type based on file extension
   const getFileType = (url) => {
      const extension = url.split('.').pop().toLowerCase();
      if (['pdf'].includes(extension)) {
         return 'pdf';
      } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
         return 'image';
      } else if (['txt'].includes(extension)) {
         return 'text';
      } else {
         return 'unknown';
      }
   };

   const handleShow = async (modal, data = null, refLetter = null) => {
      console.log(modal)
      if (Modal === 'viewMoreDetailsModal') {
         setShow({ ...show, viewMore: true })
      } else {
         setShow({ ...show, helper: true })
         if (data === 'letter') {
            const fileStatus = await checkFileExists(refLetter);
            console.log('this is file status', refLetter);
            setIsFileExists(fileStatus);
            let imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${refLetter}`;
            setHelperData({ letter: true, refLetter: imgView });
         }
      }
   }

   const checkFileExists = async (refLetter) => {
      try {
         const response = await fetch(refLetter);
         if (response.ok) {
            return true;
         } else {
            return false;
         }
      } catch (error) {
         console.error('Error checking document URL:', error);
         return false;
      }
   };

   // handle Zoom Effect 
   const handleZoomIn = () => {
      const docViewerImage = document.getElementById('image-img');
      docViewerImage.style.minWidth = `${(docViewerImage.offsetWidth - 20)}px`;
   }

   let count = 1;
   const handleZoomOut = () => {
      const docViewerImage = document.getElementById('image-img');
      if (count <= 5) docViewerImage.style.minWidth = `${(docViewerImage.offsetWidth + 20)}px`; count++;
   }

   const getStatusMessage = (step1Status, step1Reason, step2Status, step2Reason) => {
      // Check if Step 1 is cancelled
      if (step1Status === "Candidate Selection Cancelled") {
         return `Step 1: ${step1Status} - ${step1Reason}`;
      }

      if (step1Status === `Work Permit Rejected`) {
         return `Step 1: ${step1Status}`;
      }

      // Check if Step 2 is cancelled
      if (step2Status === "Candidate Selection Cancelled") {
         return `Step 2: ${step2Status} - ${step2Reason}`;
      }

      // Check if Step 2 is work started
      if (step2Status === "Work Permit Issued and Work Started") {
         return `Step 2: Work Permit Issued and Work Started`;
      }

      // Default return if none of the conditions are met
      return null;
   };



   const fileType = getFileType(documentModal.file);

   return (
      <div className='selectedCandidate-conatiner'>
         <div className='selectedCandidate-wrapper py-5'>
            <Container>
               <Col sm={12}>
                  <div className="text-center pb-5">
                     <div className="form-check form-check-inline mb-3 mb-sm-0">
                        <input className=" d-none" type="radio" name="in-process" id="currentin-process" value="currentin-process"
                           checked={(dataType === 'currentin-process') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-blue btn-emp-interview" htmlFor="currentin-process">In-Process</label>
                     </div>
                     <div className="form-check form-check-inline">
                        <input className="d-none" type="radio" name="Completed" id="Completed" value="Completed"
                           checked={(dataType === 'Completed') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-blue btn-emp-history" htmlFor="Completed">Completed</label>
                     </div>
                  </div>
               </Col>
               <div className='onboarding-list'>
                  <div className="table-responsive selectedCandidateList">
                     {
                        (dataType === 'currentin-process') &&
                        <Table className="table table-striped m-0 tb">
                           <Thead className='table-dark text-center'>
                              <Tr key={'A'}>
                                 <Th className='py-3 hash' scope="col">#</Th>
                                 <Th></Th>
                                 <Th className='py-3' scope="col">Candidate</Th>
                                 <Th className='py-3' scope="col">Candidate Type</Th>
                                 <Th className='py-3' scope="col">Selected for Onboarding on</Th>
                                 <Th className='py-3' scope="col">Status</Th>
                                 <Th className='py-3' scope="col">Action</Th>
                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              {
                                 onboardingData?.map((obdata, index) => {
                                    return (<>
                                       <Tr key={index}>
                                          <Td className='srNo'>{index + 1}</Td>
                                          <Td className='profile-wrapper'>
                                             <div className="profile-picture">
                                                {obdata.candidateDetails[0]?.name?.data.charAt(0)?.toUpperCase()}
                                             </div>
                                          </Td>
                                          <Td> {obdata.candidateDetails[0]?.name?.data.length > 20 ? `${obdata.candidateDetails[0]?.name?.data.slice(0, 20)}...` : obdata.candidateDetails[0]?.name?.data}</Td>
                                          <Td>{obdata?.Candidate_Type}</Td>
                                          <Td>{
                                             new Date(obdata.onboarding_date_time).toLocaleString('en-GB', {
                                                year: 'numeric', month: '2-digit', day: '2-digit',
                                             })
                                          }</Td>
                                          <Td><div>{obdata.status?.step1}</div><div>{obdata.status?.step2}</div></Td>
                                          <Td>
                                             <button className='btn btn-blue' onClick={() => handleViewDetails(index)}>View Documents</button>
                                          </Td>
                                       </Tr>
                                       <Tr key={`${index}.${index}`}>
                                          <Td colSpan="7" >
                                             <div className="steps-wrapper">
                                                <div className={`steps step1 ${(['Before Arrival Process Completed', 'Candidate Selection Cancelled'].includes(obdata?.status?.step1)) && 'inactive'}`}>
                                                   <span className='srNo'>1</span>
                                                   <div className='d-flex flex-column flex-sm-row flex-wrap justify-content-center gap-30 gap-sm-20'>
                                                      <span className='title'>Before-Arrival Steps</span>
                                                      <button className='btn btn-blue btn-guide'> Click here for Guide</button>
                                                   </div>
                                                   <div className='d-flex flex-column flex-sm-row flex-wrap justify-content-center gap-20'>
                                                      <div>
                                                         <div className={`floating-control status text-start 
                                                ${(obdata?.beforeArrivalSteps?.error) && 'invalid'}`}>
                                                            <label htmlFor="beforeArrivalSteps" className="label">Before-Arrival Status</label>
                                                            <div className="position-relative">
                                                               <Form.Select id='beforeArrivalSteps' name='beforeArrivalSteps' onChange={(e) => handleStatus(e, index, 'beforeArrivalSteps')} value={`${obdata?.beforeArrivalSteps?.value}`} defaultValue={``}>
                                                                  <option value="" disabled>
                                                                     Select
                                                                  </option>
                                                                  {(() => {
                                                                     let options = [];
                                                                     switch (obdata?.Candidate_Type) {
                                                                        case "New":
                                                                           options = NewBeforeArrival;
                                                                           break;
                                                                        case "Transfer":
                                                                           options = TransferBeforeArrival;
                                                                           break;
                                                                        case "Ex-Singapore":
                                                                           options = ExSingaporeBeforeArrival;
                                                                           break;
                                                                        default:
                                                                           options = []; // No options for unknown types
                                                                     }
                                                                     <option value="" disbaled>Select</option>
                                                                     return options.map((option, index) => (

                                                                        <option key={index} value={option.replace(/\s+/g, '')}>
                                                                           {option}
                                                                        </option>
                                                                     ));
                                                                  })()}
                                                               </Form.Select>
                                                               {/* <IoIosArrowDown className='arrowDown' /> */}
                                                            </div>

                                                         </div>

                                                         {(obdata?.beforeArrivalSteps?.value === 'ArrivalDateConfirmed') &&
                                                            <div className={`requiredMark floating-control mt-2 text-start 
                                                      ${(obdata?.beforeArrivalSteps?.arrivalDateError) && 'invalid'}`}>
                                                               <label htmlFor="cancellationReason" className="label"> Arrival Date</label>
                                                               <Datetime
                                                                  value={(obdata?.beforeArrivalSteps?.arrivalDate) ? new Date(obdata?.beforeArrivalSteps?.arrivalDate) : new Date()}
                                                                  onChange={(date) => handleArrivalDate(date, index, 'beforeArrivalSteps')}
                                                                  dateFormat="DD/MM/YYYY"
                                                                  timeFormat={false}
                                                                  renderInput={renderInput}
                                                                  inputProps={{ placeholder: "Select" }}
                                                                  closeOnSelect={true}
                                                               />
                                                            </div>
                                                         }

                                                         {(obdata?.beforeArrivalSteps?.value === 'SIPTrainingBooked') &&
                                                            <div className={`requiredMark floating-control mt-2 text-start 
                                                      ${(obdata?.beforeArrivalSteps?.SIPTrainingDateError) && 'invalid'}`}>
                                                               <label htmlFor="SIPTrainingDate" className="label"> SIP Training Date</label>
                                                               <Datetime
                                                                  value={(obdata?.beforeArrivalSteps?.SIPTrainingDate) ? new Date(obdata?.beforeArrivalSteps?.SIPTrainingDate) : new Date()}
                                                                  onChange={(date) => handleSIPTrainingDate(date, index, 'beforeArrivalSteps')}
                                                                  dateFormat="DD/MM/YYYY"
                                                                  timeFormat={false}
                                                                  renderInput={renderInput}
                                                                  inputProps={{ placeholder: "Select" }}
                                                                  closeOnSelect={true}
                                                               />
                                                            </div>
                                                         }
                                                      </div>

                                                      <button className='btn btn-success align-self-center' data-step='1'
                                                         onClick={(e) => handleConfirmStatus(e, index)}
                                                         disabled={obdata?.isLoading1}>
                                                         {(obdata?.isLoading1) && <Spinner animation="border" size="sm" />}
                                                         Confirm Status
                                                      </button>
                                                   </div>
                                                </div>

                                                <div className={`steps step2 
                                             ${(obdata?.status?.step1 !== 'Before Arrival Process Completed' || ['Work Permit Issued and Work Started', 'Candidate Selection Cancelled'].includes(obdata?.status?.step2)) && 'inactive'}
                                             `}>
                                                   <span className='srNo'>2</span>
                                                   <div className='d-flex flex-column flex-sm-row flex-wrap justify-content-center gap-30 gap-sm-20'>
                                                      <span className='title'>On-Arrival Steps</span>
                                                      <button className='btn btn-blue btn-guide'> Click here for Guide</button>
                                                   </div>
                                                   <div className='d-flex  flex-column flex-sm-row flex-wrap justify-content-center gap-20'>
                                                      <div>
                                                         <div className={`floating-control status text-start 
                                                ${(obdata?.onArrivalSteps?.error) && 'invalid'}`}>
                                                            <label htmlFor="onArrivalSteps" className="label"> On-Arrival Steps</label>
                                                            <div className="position-relative">
                                                               <Form.Select id='onArrivalSteps' name='onArrivalSteps' onChange={(e) => handleStatus(e, index, 'onArrivalSteps')} value={`${obdata.onArrivalSteps.value}`} defaultValue={`Select`}>
                                                                  <option value="" disabled>
                                                                     Select
                                                                  </option>
                                                                  {(() => {
                                                                     let options = [];
                                                                     switch (obdata?.Candidate_Type) {
                                                                        case "New":
                                                                           options = NewOnArrival;
                                                                           break;
                                                                        case "Transfer":
                                                                           options = TransferOnArrival;
                                                                           break;
                                                                        case "Ex-Singapore":
                                                                           options = ExSingaporeOnArrival;
                                                                           break;
                                                                        default:
                                                                           options = ["Select"]; // No options for unknown types
                                                                     }

                                                                     return options.map((option, index) => (
                                                                        <option key={index} value={option.replace(/\s+/g, '')}>
                                                                           {option}
                                                                        </option>
                                                                     ));
                                                                  })()}

                                                               </Form.Select>

                                                            </div>
                                                         </div>
                                                         {/* {(obdata?.onArrivalSteps?.value === 'CandidateSelectionCancelled') &&
                                                            <div className={`requiredMark floating-control status text-start  mt-2
                                                ${(obdata?.onArrivalSteps?.cancellationReasonError) && 'invalid'}`}>
                                                               <label htmlFor="cancellationReason" className='label'>Cancellation Reason</label>
                                                               <input type="text" className="form-control" id="cancellationReason" placeholder='Type Here' onChange={(e) => handleCancellationReason(e, index, 'onArrivalSteps')} value={obdata.onArrivalSteps.cancellationReason} />
                                                            </div>
                                                         } */}
                                                      </div>
                                                      <button className='btn btn-success align-self-center' data-step='2'
                                                         onClick={(e) => handleConfirmStatus(e, index)}
                                                         disabled={obdata?.isLoading2}>
                                                         {(obdata?.isLoading2) && <Spinner animation="border" size="sm" />} Confirm Status
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>
                                          </Td>
                                       </Tr>
                                    </>)
                                 })
                              }

                              {
                                 (onboardingData.length === 0) && <Tr>
                                    <Th colSpan='7'>Record Not Found.</Th>
                                 </Tr>
                              }

                           </Tbody>
                        </Table>
                     }
                     {
                        (dataType === 'Completed') &&
                        <Table className="table table-striped m-0 tb">
                           <Thead className='table-dark text-center'>
                              <Tr key={'A'}>
                                 <Th className='py-3 hash' scope="col">#</Th>
                                 <Th></Th>
                                 <Th className='py-3' scope="col">Candidate</Th>
                                 <Th className='py-3' scope="col">Candidate Type</Th>
                                 <Th className='py-3' scope="col">Selected for Onboarding on</Th>
                                 <Th className='py-3' scope="col">Status</Th>

                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              {
                                 onboardingHistoryData?.map((obdata, index) => {
                                    return (<>
                                       <Tr key={index}>
                                          <Td className='srNo'>{index + 1}</Td>
                                          <Td className='profile-wrapper'>
                                             <div className="profile-picture">
                                                {obdata.candidateDetails[0]?.name?.data.charAt(0)?.toUpperCase()}
                                             </div>
                                          </Td>
                                          <Td> {obdata.candidateDetails[0]?.name?.data.length > 20 ? `${obdata.candidateDetails[0]?.name?.data.slice(0, 20)}...` : obdata.candidateDetails[0]?.name?.data}</Td>
                                          <Td>{obdata?.Candidate_Type}</Td>
                                          <Td>{
                                             new Date(obdata.onboarding_date_time).toLocaleString('en-GB', {
                                                year: 'numeric', month: '2-digit', day: '2-digit',
                                             })
                                          }</Td>

                                          <Td>{getStatusMessage(obdata.status.step1, obdata.step1Reason, obdata.status.step2, obdata.step2Reason)}
                                          </Td>

                                       </Tr>
                                    </>)
                                 })
                              }

                              {
                                 (onboardingHistoryData.length === 0) && <Tr>
                                    <Th colSpan='7'>Record Not Found.</Th>
                                 </Tr>
                              }

                           </Tbody>
                        </Table>
                     }
                  </div>
               </div>
            </Container>
         </div>

         {/* confirmation modal  */}
         <Modal show={modal} onHide={() => setModal(false)}
            id='selectCandidateModal'>
            <Modal.Body>
               {
                  (selectedValue !== "WorkPermitRejected" && selectedValue !== "CandidateSelectionCancelled" && selectedValue !== "WorkPermitIssuedandWorkStarted") &&
                  <div className='text-center'>
                     <h5>Are you sure?</h5>
                  </div>
               }
               {
                  (selectedValue === "WorkPermitRejected") &&
                  <div className='text-center'>
                     <h5>This will end the Selection process.</h5>
                     <h5>Are you sure?</h5>
                  </div>
               }
               {
                  (selectedValue === "WorkPermitIssuedandWorkStarted") &&
                  <div className='text-center'>
                     <h5>Selection process will be completed.</h5>
                     <h5>Are you sure?</h5>
                  </div>
               }
               {
                  (selectedValue === "CandidateSelectionCancelled") &&
                  <div className='text-center'>
                     <h5>This will end the Selection process.</h5>
                     <br />
                     <div className="floating-control flex-grow-1 requiredMark status text-start  mt-2">
                        {

                           (step === '1') ?
                              <input type="text" name="cancellationReason" id="cancellationReason" className={`floating-input  ${(reasonError) && 'invalid'}`}
                                 placeholder="none" onChange={(e) => handleCancellationReason(e, index, 'beforeArrivalSteps')} />
                              :
                              <input type="text" className={`floating-input  ${(reasonError) && 'invalid'}`} placeholder="none" id="cancellationReason"
                                 onChange={(e) => handleCancellationReason(e, index, 'onArrivalSteps')} />
                        }


                        <label htmlFor="ReasonForCancel" className="floating-label">Reason For Cancel</label>
                     </div>
                  </div>
               }

            </Modal.Body>
            <Modal.Footer className='flex-wrap justify-content-center'>
               <div className="gap-30">
                  <button className='btn btn-primary mx-1 ' type="button" onClick={handleConfirmationOk} >
                     {(selectedValue === "CandidateSelectionCancelled") ? "Save" : "Yes"}
                  </button>
                  <button className='btn btn-secondary mx-1' onClick={() => setModal(false)}>
                     {(selectedValue === "CandidateSelectionCancelled") ? "Cancel" : "No"}
                  </button>

               </div>
            </Modal.Footer>
         </Modal>


         {/* viewMoreDetailsModal */}
         {/* viewMoreDetailsModal */}
         <Modal size='lg' show={show.viewMore} onHide={() => handleClose('viewMoreDetailsModal')} backdrop='static' id='viewMoreDetailsModal'>
            {details.map((details, index) => (
               <Modal.Body className='p-0 '>
                  <div className='position-relative'>
                     <AiOutlineClose onClick={() => handleClose('viewMoreDetailsModal')}
                        className='icon close-icon position-absolute' />
                  </div>
                  <div className='candidate-details pb-5'>
                     <div className='description-1 d-lg-flex '>
                        <div className='profile-wrapper d-block'>
                           <img src={details?.photoPreviewUrl} onError={imageOnError}
                              alt='profile' className='profile cursor-pointer' />
                        </div>
                        <div className='flex-grow-1 d-flex flex-column justify-content-between mt-2 mt-lg-0 p-1'>
                           <p className='name text-center text-lg-start '>{details?.name.data}</p>

                           <div className='d-lg-flex'>
                              <p className='dob me-lg-5 text-center text-lg-start'>
                                 <span className='text-nowrap' > Employment Type :</span>
                                 <span className='text-nowrap'>{details?.employmentType?.data}</span>
                              </p>
                           </div>
                           <div className='d-lg-flex'>
                              <p className='dob me-lg-5 text-center text-lg-start'>
                                 <span className='text-nowrap' > Address :</span>
                                 <span className='text-nowrap'>{details?.address?.data}</span>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>

                     </div>
                     <div className='description-2 px-3'>
                        <p className='nationality'>Nationality : <span>{details?.nationality.data}</span></p>
                        <p>Date Of Birth: <span>{details?.dob}</span></p>
                        <p className='maritalStatus'>Marital Status: <span>{details?.maritalStatus.data}</span></p>
                        <p>No. of kids : <span>{details?.childAge?.data.length || "--"}</span> </p>
                     </div>
                     <br />
                     <div className='skills mb-2'>
                        <h3 className='px-3'>View/Download Documents:</h3>
                        <div className="row justify-content-center">
                           <div className="col-auto">
                              <button className="btn btn-primary" onClick={() => viewDocuments(details.passport.data)}>View Passport</button>
                           </div>
                           <div className="col-auto">
                              <button className="btn btn-primary" onClick={() => viewDocuments(details.educationCertificates.data)}>View Educational Certificate</button>
                           </div>
                           <div className="col-auto">
                              <button className="btn btn-primary" onClick={() => viewDocuments(details.medicalReport.data)}>View Medical Reports</button>
                           </div>
                        </div>
                     </div>
                     <br />
                     <div className='workDetails '>
                        <h3 className='px-3'>Work Details:</h3>
                        {(details?.workExperience?.data.length > 0) ?
                           <div className='table-responsive px-3'>
                              <Table className='table table-bordered mt-3'>
                                 <Thead className='table-dark text-center'>
                                    <Tr>
                                       <Th>Period</Th>
                                       <Th>Country</Th>
                                       <Th>Duties</Th>
                                       <Th className='text-nowrap'>Any Ref.Letter</Th>
                                    </Tr>
                                 </Thead>
                                 <Tbody className='text-center'>
                                    {
                                       details?.workExperience?.data.map((workExperience, index) => (
                                          <Tr key={index}>
                                             <Td className='period tbl-td '>
                                                <span className='text-nowrap'>
                                                   {
                                                      new Date(workExperience.from).toLocaleString('en-GB', {
                                                         year: 'numeric', month: 'short'
                                                      }).replace(/ /g, '-')
                                                   }
                                                </span>
                                                <span className='mx-2 text-nowrap'>to</span>
                                                <span className='text-nowrap'>
                                                   {
                                                      (workExperience.present) ? 'Present' : new Date(workExperience.to).toLocaleString('en-GB', {
                                                         year: 'numeric', month: 'short'
                                                      }).replace(/ /g, '-')
                                                   }
                                                </span>
                                             </Td>
                                             <Td className='tbl-td'>{workExperience.country || '-'}</Td>
                                             <Td>{workExperience.duties.toString() || '-'}</Td>
                                             <Td className='tbl-td'>
                                                {
                                                   (workExperience?.refLetter) ?
                                                      <>
                                                         <span className='eye-icon mx-2 cursor-pointer' onClick={() => handleShow('helperModal', 'letter', `${workExperience?.refLetter}`)}>
                                                            <TfiEye />
                                                         </span>
                                                      </>
                                                      : 'No'
                                                }

                                             </Td>
                                          </Tr>
                                       ))
                                    }
                                 </Tbody>
                              </Table>
                           </div> : "Nil"
                        }
                     </div>
                  </div>
               </Modal.Body>
            ))}
         </Modal>
         {/* view Document file  */}
         <Modal
            size="lg"
            show={documentModal.show}
            onHide={() => setdocumentModal({ ...documentModal, show: false })}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => setdocumentModal({ ...documentModal, show: false })}
                     className='icon close-icon-blue position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className='d-flex justify-content-center'>
                  <div className='icon mx-1 mx-sm-3' onClick={downloadDocuments}>
                     <img src="/images/download.png" alt="download" className='download' />
                  </div>
                  <div className='icon mx-1 mx-sm-3' onClick={printFile}>
                     <img src="/images/printer.png" alt="printer" className='printer' />
                  </div>
               </div>
               <div className="p-4">
                  {fileType === 'pdf' && (
                     <>
                        <Document
                           file={documentModal.file}
                           onLoadSuccess={(data) => {
                              setTotalPages(data.numPages);
                           }}
                        >
                           <Page
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                              pageNumber={pageNum}
                              className='w-100 border'
                           />
                        </Document>
                        {totalPages > 1 && (
                           <div className="page-controls d-flex justify-content-center align-items-center mt-2">
                              <button type="button" className='btn btn-primary' onClick={pdfPrevPage}
                                 disabled={pageNum === 1}>‹</button>
                              <span className='mx-2'>{pageNum} of {totalPages}</span>
                              <button type="button" className='btn btn-primary' onClick={pdfNextPage}
                                 disabled={pageNum === totalPages}>›</button>
                           </div>
                        )}
                     </>
                  )}
                  {fileType === 'image' && (
                     <div className="text-center">
                        <img src={documentModal.file} alt="Document" style={{ maxWidth: '100%' }} />
                     </div>
                  )}
                  {fileType === 'text' && (
                     <div className="text-content">
                        <iframe
                           src={documentModal.file}
                           title="Text Document"
                           style={{ width: '100%', height: '80vh', border: 'none' }}
                        />
                     </div>
                  )}

                  {fileType === 'unknown' && <p>Cannot display this file type</p>}
               </div>
            </Modal.Body>
         </Modal>

         {/* helpermodal for video and ref.letter   */}
         <Modal show={show.helper} onHide={() => handleClose('helperModal')} backdrop='static' id='helperModal'
            aria-labelledby='contained-modal-title-vcenter' centered dialogClassName={`w-min-content ${(helperData?.refLetter?.includes('pdf')) ? 'pdf' : ''}`}
         >
            <Modal.Body className='p-0 justify-content-end min-h-100'>
               <div className='position-relative'>
                  <AiOutlineClose onClick={() => handleClose('helperModal')}
                     className='icon close-icon position-absolute' />
               </div>
               <div>
                  {(helperData?.letter && (!helperData?.refLetter?.includes('pdf'))) &&
                     <div className='d-flex justify-content-center gap-10 p-3'>
                        <button className='btn-zoom zoon-in' onClick={handleZoomIn}><FiZoomOut /> </button>
                        <button className='btn-zoom zoom-out' onClick={handleZoomOut}><FiZoomIn /></button>
                     </div>
                  }
                  {(helperData?.letter) && <DocViewer
                     className="doc-viewer"
                     documents={[{ uri: helperData?.refLetter, fileName: "Reference_letter", }]}
                     pluginRenderers={DocViewerRenderers}
                     config={{
                        header: {
                           disableHeader: true,
                           disableFileName: true,
                           retainURLParams: true,
                        }
                     }} />
                  }
                  {/*((!isFileExists) && (helperData?.letter)) && <p className='text-center p-3'>Error: Failed to load the document.</p>*/}
               </div>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default SelectedCandidate