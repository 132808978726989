import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import DialogAlert from '../../../../components/customModal/customAlertModal';
import Select from "react-select";
import CountrySelector from '../../../../components/selectWithSearch/selectSearch';

//hooks
import { useAuthContext } from '../../../../hooks/useAuthContext';
import getBaseUrl from '../../../../hooks/http'

//form
import EmpUserForm from './EmpUserForm';
import EmpConfirmForm from './EmpConfirmForm';
import EmpOtpForm from './EmpOtpForm';
import EmpAccountSuccess from './EmpAccountSuccess';
//css
import './empAccount.css'


const INITIAL_DATA = {
  name: '',
  dialingCode: '',
  phoneNumber: '',
  email: '',
  otp: '',
  fullname: '',
  Citizenship: '',
  ResidentialStatus:''
}

const EmpAccount = () => {
  const { user, dispatch } = useAuthContext();
  const profilePhoto = useRef(null);

  const [countries, setCountries] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(false)
  const [employer, setEmployer] = useState({
    id: '', name: '', phoneNumber: '',
    email: '', photo: '',
    profilePreview: '', oldPhoto: '',
    agreement: '', fullname: '',
    Citizenship: '',
    ResidentialStatus:''
  })
  const [modal, setmodal] = useState({
    show: false,
    changeField: '',
  });
  const [error, seterror] = useState({
    name: false
  })
  const [changeProfileModal, setChangeProfileModal] = useState({
    show: false,
    file: ''
  })
  const [changeNameModal, setChangeNameModal] = useState({
    show: false,
    changeField: '',
  })

  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  //handle view Agreement Button
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  //multi step form --------------------------------
  const [data, setData] = useState(INITIAL_DATA)
  const [currentStepIndex, setcurrentStepIndex] = useState(0)
  const updateFields = (fields) => {
    setData(prev => {
      seterror({
        name: false
      });
      return { ...prev, ...fields }
    })


  }
  const pages = [
    <EmpUserForm data={data} employer={employer} updateFields={updateFields} changeField={modal.changeField} setcurrentStepIndex={setcurrentStepIndex} />,
    <EmpConfirmForm data={data} changeField={modal.changeField} setcurrentStepIndex={setcurrentStepIndex} />,
    <EmpOtpForm data={data} updateFields={updateFields} changeField={modal.changeField} setcurrentStepIndex={setcurrentStepIndex} />,
    <EmpAccountSuccess data={data} employer={employer} changeField={modal.changeField} />
  ]

  //End  --------------------------------

  // modal -------------------------------
  const handleClose = () => setmodal({ ...modal, show: false });
  const handleShow = (changeField) => {
    setmodal({ ...modal, show: true, changeField });
    setcurrentStepIndex(0);
  }
  // End -------------------------------
  // change profile modal -------------------------------
  const handleChangeProfileModalClose = () => {
    setChangeProfileModal({ ...changeProfileModal, show: false });
    // setEmployer({ ...employer, photo: employer.oldPhoto, profilePreview: employer.oldPhoto })
  }
  const handleChangeProfileModalShow = () => setChangeProfileModal({ ...changeProfileModal, show: true });
  // End -------------------------------

  const handleDailogClose = () => {
    setOpen(false);
  };

  const handleCitizenChange = (selectedOption) => {
     setData({...data,Citizenship:selectedOption});
  };

  
  const handleResidentialChange = (selectedOption) => {
    setData({...data,ResidentialStatus:selectedOption});
 };
  



  const getEmployer = async (action = '') => {
    try {
      if (user !== null) {
        let config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const response = await axios.get(`${getBaseUrl}/employer/account`, config);
        const { _id, name, photo, phoneNumber, email, agreement,fullname,Citizenship,ResidentialStatus } = response.data.details;
        const profilePreview = (photo) ? `${getBaseUrl}/candidates/getMediaFileFromDb/${photo}` : '/uploads/userIcon.png';
        const newEmail = (email) ? email : "demo@example.com";
        setEmployer({
          ...employer, id: _id,
          name, phoneNumber, email: newEmail, photo,
          profilePreview, oldPhoto: photo, agreement,
          fullname,Citizenship,ResidentialStatus
        })

        if (action === 'changeProfile') userUpdate({ photo: photo }) //Update User Data
      }
    } catch (error) {
      console.log(error);
    }
  }



  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          const countryOptions = data.map((country) => ({
            value: country.name.common,
            label: (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={country.flags.svg}
                  alt={country.name.common}
                  style={{ width: "20px", height: "15px", marginRight: "10px" }}
                />
                {country.name.common}
              </div>
            ),
          }));

          const singapore = countryOptions.filter((c) => c.value === "Singapore");
        const otherCountries = countryOptions
          .filter((c) => c.value !== "Singapore")
          .sort((a, b) => a.value.localeCompare(b.value));

  
          setCountries([...singapore, ...otherCountries]);
        });
    if (user !== null) {
      const isAgreementSign = user.Is_AgreementSign;
      if (isAgreementSign === "Yes") {
        setIsButtonDisabled(false)
      }
      else {
        setIsButtonDisabled(true)
      }
    }
    getEmployer();
  }, [user])


  //update User data
  const userUpdate = (updatedData) => {
    const userInfo = {
      ...JSON.parse(localStorage.getItem('user')),
      ...updatedData
    };
    /* update the auth context */
    dispatch({ type: 'UPDATE-USER', payload: userInfo })
  }


  //handleUploadPhoto -----------------
  const changeProfile = async (file = '') => {
    try {
      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      let data = new FormData()
      data.append('photo', file)
      data.append('oldPhoto', employer.oldPhoto)

      const result = await axios.post(`${getBaseUrl}/employer/update-photo`, data, config);
      setChangeProfileModal({ ...changeProfileModal, show: false })
      getEmployer('changeProfile')
    } catch (error) {
      console.log(error);
    }
  }

  //show Name,citizenshi,---

  // const showNameModel = async () => {
  //   setChangeNameModal({ show: true })
  //   setData({ ...data, name: '' });
  // }


  const showNameModel = async (changeField) => {
    setChangeNameModal({ ...changeNameModal,show: true ,changeField})
    setData({ ...data, name: '' });
  }

  //change Name  -----------------
  // const changeName = async (changingFiled,filedValue) => {
  //   try {
  //     if (changingFiled==='name') {
  //       let config = {
  //         headers: {
  //           Authorization: `Bearer ${user.token}`,
  //         },
  //       };

  //       const body = { name: data.name }
  //       const result = await axios.post(`${getBaseUrl}/employer/update-name`, body, config);
  //       console.log(result.data);
  //       setChangeNameModal({ ...changeNameModal, show: false })
  //       getEmployer()

  //       //Update User Data
  //       userUpdate({ name: data.name })
  //     } else {
  //       seterror({
  //         name: true
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  
  const ResidentialStatusArray = [
    { value: 'Singapore Citizen', label: 'Singapore Citizen' },
    { value: 'Permanent Residence', label: 'Permanent Residence' },
    { value: 'Foreigner', label: 'Foreigner' }
  ]
  const changeField = async (changingField, fieldValue) => {
    try {
      if (fieldValue) {
        // Configuration for the request
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
  
        // Construct the request body dynamically
        const body = { field: changingField, value: (changingField==="Citizenship" || changingField==="ResidentialStatus") ? fieldValue.value :  fieldValue};
        // Construct the API endpoint dynamically
        const result = await axios.post(
          `${getBaseUrl}/employer/update-name`,
          body,
          config
        ).then(async (res) => {
          if (res.status === 200) {
            console.log(res);
            getEmployer();
            setChangeNameModal({ ...changeNameModal, show: false })
          } })
          .catch((error) => {
             // handle error
             console.log(error);
          });
        // Close modal or perform any UI updates
       
  
       
  
        // Update user data (if needed)
        if (changingField === "name") {
          userUpdate({ name: fieldValue });
        }

         // Refresh employer data
        
      } else {
        // Set error for invalid input
        seterror({
          [changingField]: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  






  // This function will be triggered when the file field change
  const imageChange = (e) => {
    const file = e.target.files[0];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

    if (!validImageTypes.includes(file['type'])) {
      // invalid file type code goes here.
      setOpen(true);
      setDialogMessage("Invalid file types. Only .jpg, .jpeg, .png, files allowed")
      return 0;
    }
    if (file.size > 1048576) {
      // invalid file type code goes here.
      setOpen(true);
      setDialogMessage("File size exceeds the limit (1 MB)")
      return 0;
   }
    if (e.target.files && e.target.files.length > 0) {
      setEmployer({ ...employer, photo: e.target.files[0], profilePreview: e.target.files[0] })
      setTimeout(() => {
        changeProfile(e.target.files[0]);
      }, 1000);
    }
  };

  //handle imageOnError
  const imageOnError = (event) => {
    event.target.src = '/uploads/userIcon.png';
  };

  //viewAgreement ---------------------------------------
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const [agreementModal, setAgreementModal] = useState({
    show: false,
    file: ''
  })
  const viewAgreement = () => {
    const employerId = employer.id;
    setAgreementModal({ ...agreementModal, show: true, file: `${getBaseUrl}/candidates/getMediaFileFromDb/${employer.agreement}` })
  }
  //pdf -----------------------------------------------
  const pdfPrevPage = () => {
    setPageNum(pageNum - 1)
  }
  const pdfNextPage = () => {
    setPageNum(pageNum + 1)
  }

  //download pdf --------------------------------------------
  const downloadPdf = async () => {
    if (user !== null) {
      const response = await axios.get(`${getBaseUrl}/agreement/download-pdf/${employer.agreement}`, {
        responseType: 'blob', // Important: tells Axios to treat the response as binary data (file)
      });

      // Create a blob from the file data and download it
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const filename = "agreement.pdf"
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); // Set the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up

      // axios.post(`${getBaseUrl}/agreement/download-pdf/`, data, config)
      //   .then(async (res) => {
      //     const a = document.createElement("a");
      //     a.href = res?.data?.file;
      //     a.download = 'agreement.pdf';
      //     a.click();
      //   })
      //   .catch((error) => {
      //     // handle error
      //     console.log(error);
      //   })
    }
  }

  //print doc ------------------------------------------------
  const printPdf = async () => {
    const url = `${getBaseUrl}`.replace(/\/api/g, '')
    // Replace 'path-to-your-pdf.pdf' with the actual path to your PDF file
    const pdfUrl = `${agreementModal.file}`;

    // Open the PDF in a new tab
    const newTab = window.open(pdfUrl, '_blank');

    // Wait for the PDF to load, then trigger the print dialog
    newTab.onload = () => {
      newTab.print();
    };
  }

  // handle Confirmation modal 'Ok' click
  const handleConfirmationOk = () => {
    profilePhoto.current.click();
    setConfirmationModal(false);
  }


  return (
    <div className='emp_account py-5'>
      <Container>
        <h2 className='title'>My Account</h2>
        <h4 className='sub-title'>Manage your Account</h4>
        <Row className='mt-5 justify-content-center'>
          <Col sm={10} md={6} lg={5} className='order-1 order-lg-0'>
            <div className='w-100 h-100 d-flex flex-column justify-content-center'>
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <div className="floating-control flex-grow-1">
                  <input type="text" name="name" id='name' className="floating-input"
                    placeholder="none" readOnly value={employer.name} />
                  <label htmlFor="phone" className="floating-label">Name</label>
                </div>
                <span className='change-text' onClick={()=>{showNameModel('name')}}>Change</span>
              </div>
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <div className="floating-control flex-grow-1">
                  <input type="text" name="phone" id='phone' className="floating-input"
                    placeholder="none" readOnly defaultValue={employer.phoneNumber ? `+${employer.phoneNumber}` : ''} />
                  <label htmlFor="phone" className="floating-label">Phone Number</label>
                </div>
                <span className='change-text' onClick={() => handleShow('phone')}>Change</span>
              </div>
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <div className="floating-control flex-grow-1">
                  <input type="text" name="email" id='email' className="floating-input"
                    placeholder="none" readOnly defaultValue={employer.email} />
                  <label htmlFor="email" className="floating-label">Email</label>
                </div>
                <span className='change-text' onClick={() => handleShow('email')}>
                  {(employer.email) ? 'Change' : 'Add'}
                </span>
              </div>
              { (employer.fullname && employer.ResidentialStatus && employer.Citizenship) && 
              <>
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <div className="floating-control flex-grow-1">
                  <input type="text" name="fullname" id='fullname' className="floating-input"
                    placeholder="none" readOnly value={employer.fullname} />
                  <label htmlFor="phone" className="floating-label">Full Name as per Document</label>
                </div>
                <span className='change-text' onClick={()=>{showNameModel('fullname')}}>Change</span>
              </div>
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <div className="floating-control flex-grow-1">
                  <input type="text" name="ResidentialStatus" id='Residential Status' className="floating-input"
                    placeholder="none" readOnly value={employer.ResidentialStatus} />
                  <label htmlFor="phone" className="floating-label">Residential Status</label>
                </div>
                <span className='change-text' onClick={()=>{showNameModel('ResidentialStatus')}}>Change</span>
              </div>
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <div className="floating-control flex-grow-1">
                  <input type="text" name="Citizenship" id='Citizenship' className="floating-input"
                    placeholder="none" readOnly value={employer.Citizenship} />
                  <label htmlFor="phone" className="floating-label">Citizenship</label>
                </div>
                <span className='change-text' onClick={()=>{showNameModel('Citizenship')}}>Change</span>
              </div>
              </>
              }
              <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                <button className='btn btn-primary' onClick={viewAgreement} disabled={isButtonDisabled}>View Agreement</button>
              </div>
            </div>
          </Col>
          <Col lg={4} className='order-0 order-lg-1 mb-4'>
            <div className=''>
              <div className="account-photo mx-auto">
                <img src={employer.profilePreview} alt="profile"
                  className='account-photo-img mb-3 w-100 h-100'
                  onError={imageOnError} />
              </div>
              <button className='btn btn-primary btn-outline-blue px-5 mt-4 mx-auto d-block'
                onClick={handleChangeProfileModalShow}>Change Profile Photo</button>
            </div>
          </Col>
        </Row>

        {/* accountModal */}
        <Modal show={modal.show} onHide={handleClose} backdrop="static" id="accountModal" centered>
          {
            (currentStepIndex !== 3) &&
            <Modal.Header className='p-0 justify-content-end border-0'>
              <div className="position-relative">
                <AiOutlineClose onClick={handleClose}
                  className='icon close-icon-blue position-absolute' />
              </div>
            </Modal.Header>
          }
          <Modal.Body>

            <div className='mb-3'>
              {pages[currentStepIndex]}
            </div>
          </Modal.Body>
        </Modal>

        {/* changeProfileModal */}
        <Modal show={changeProfileModal.show} onHide={handleChangeProfileModalClose} backdrop="static" id="changeProfileModal" centered>
          {
            (currentStepIndex !== 3) &&
            <Modal.Header className='p-0 justify-content-end border-0'>
              <div className="position-relative">
                <AiOutlineClose onClick={handleChangeProfileModalClose}
                  className='icon close-icon-blue position-absolute' />
              </div>
            </Modal.Header>
          }
          <Modal.Body>
            <div className=''>
              <div className="account-photo mx-auto">
                <img src={employer.profilePreview} alt="profile"
                  className='account-photo-img mb-3 w-100 h-100'
                  onError={imageOnError} />
              </div>
              <div className='d-flex justify-content-evenly mt-4'>
                <button className="btn btn-outline-secondary"
                  onClick={() => changeProfile()}>Remove profile</button>
                <input ref={profilePhoto} type="file" className='d-none' id='file' accept='image/*' onChange={imageChange} />
                <button className="btn btn-primary" onClick={() => setConfirmationModal(true)}>
                  Change profile
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* change name */}
        <Modal show={changeNameModal.show} onHide={() => setChangeNameModal({ ...changeNameModal, show: false })} backdrop="static" id="changeNameModal" centered>
          <Modal.Header className='p-0 justify-content-end border-0'>
            <div className="position-relative">
              <AiOutlineClose onClick={() => setChangeNameModal({ ...changeNameModal, show: false })}
                className='icon close-icon-blue position-absolute' />
            </div>
          </Modal.Header>
          <Modal.Body>
            {
              (changeNameModal.changeField=='name') &&  <div className=''>
              <h3 className='text-center mb-4'>Enter Your New Name</h3>
              <div className="floating-control flex-grow-1">
                <input type="text" name="name" id='name' className={`floating-input ${(error.name) && 'invalid'}`}
                  placeholder="none" value={data.name}
                  onChange={(e) => updateFields({ name: e.target.value })} required />
                <label htmlFor="phone" className="floating-label">Name</label>
              </div>
              <div className='d-flex flex-wrap justify-content-evenly mt-4'>
                <button className="btn btn-outline-secondary m-1"
                  onClick={() => setChangeNameModal({ ...changeNameModal, show: false })}>Cancel</button>
                <button className="btn btn-primary m-1"
                  onClick={()=>{changeField('name',data.name)}}>Save</button>
              </div>
            </div>
            }
            {
              (changeNameModal.changeField=='fullname') &&  <div className=''>
              <h3 className='text-center mb-4'>Enter Your New  Full Name</h3>
              <div className="floating-control flex-grow-1">
                <input type="text" name="fullname" id='fullname' className={`floating-input ${(error.fullname) && 'invalid'}`}
                  placeholder="none" value={data.fullname}
                  onChange={(e) => updateFields({ fullname: e.target.value })} required />
                <label htmlFor="phone" className="floating-label">Full Name</label>
              </div>
              <div className='d-flex flex-wrap justify-content-evenly mt-4'>
                <button className="btn btn-outline-secondary m-1"
                  onClick={() => setChangeNameModal({ ...changeNameModal, show: false })}>Cancel</button>
                <button className="btn btn-primary m-1"
                  onClick={()=>{changeField("fullname",data.fullname)}}>Save</button>
              </div>
            </div>
            }
            {
              (changeNameModal.changeField=='ResidentialStatus') &&  <div className=''>
              <h3 className='text-center mb-4'>Select Your Residential Status</h3>
              <div className="floating-control flex-grow-1">
              <Select className={`floating-input`}
                name="ResidentialStatus" id='ResidentialStatus'  value={data.ResidentialStatus} onChange={handleResidentialChange}
                options={ResidentialStatusArray}
                placeholder="Select"
                styles={{
                  control: (base) => ({
                    ...base,
                    border:'none',
                    borderColor: "#ced4da",
                    borderRadius: "5px",
                    padding: "2px",
                    textAlign:"left"
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999, // Ensure dropdown renders above other elements
                    textAlign:"left"
                  }),
                }}
              />
                {/* <input type="text" name="ResidentialStatus" id='ResidentialStatus' className={`floating-input ${(error.name) && 'invalid'}`}
                  placeholder="none" value={data.ResidentialStatus}
                  onChange={(e) => updateFields({ ResidentialStatus: e.target.value })} required /> */}
                <label htmlFor="phone" className="floating-label">Residential Status</label>
              </div>
              <div className='d-flex flex-wrap justify-content-evenly mt-4'>
                <button className="btn btn-outline-secondary m-1"
                  onClick={() => setChangeNameModal({ ...changeNameModal, show: false })}>Cancel</button>
                <button className="btn btn-primary m-1"
                  onClick={()=>{changeField("ResidentialStatus",data.ResidentialStatus)}}>Save</button>
              </div>
            </div>
            }
            {
              (changeNameModal.changeField=='Citizenship') &&  <div className=''>
              <h3 className='text-center mb-4'>Select Your Citizenship</h3>
              <CountrySelector 
            options={countries}
            onChange={handleCitizenChange}
            value={data.Citizenship}
            fromError={error}
            />
              <div className='d-flex flex-wrap justify-content-evenly mt-4'>
                <button className="btn btn-outline-secondary m-1"
                  onClick={() => setChangeNameModal({ ...changeNameModal, show: false })}>Cancel</button>
                <button className="btn btn-primary m-1"
                  onClick={()=>{changeField("Citizenship",data.Citizenship)}}>Save</button>
              </div>
            </div>
            }
           
          </Modal.Body>
        </Modal>

        {/* view pdf file  */}
        <Modal
          size="lg"
          show={agreementModal.show}
          onHide={() => setAgreementModal({ ...agreementModal, show: false })}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className='p-0 justify-content-end border-0'>
            <div className="position-relative">
              <AiOutlineClose onClick={() => setAgreementModal({ ...agreementModal, show: false })}
                className='icon close-icon-blue position-absolute' />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex justify-content-center'>
              <div className='icon mx-1 mx-sm-3' onClick={downloadPdf}>
                <img src="/images/download.png" alt="download" className='download' />
              </div>
              <div className='icon mx-1 mx-sm-3' onClick={printPdf}>
                <img src="/images/printer.png" alt="printer" className='printer' />
              </div>
            </div>
            <div className="p-4">
              {
                (agreementModal.file) &&
                <>
                  <Document
                    file={agreementModal.file}
                    onLoadSuccess={(data) => {
                      setTotalPages(data.numPages);
                    }}
                  >
                    <Page
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      customTextRenderer={false}
                      pageNumber={pageNum}
                      className='w-100 border'
                      onLoadSuccess={(data) => {
                        setPageDetails(data);
                      }}
                    />
                  </Document>
                  {
                    (totalPages > 1) &&
                    <div className="page-controls d-flex justify-content-center align-items-center">
                      <button type="button" className='btn btn-primary' onClick={pdfPrevPage}
                        disabled={(pageNum === 1) ? true : false}>‹</button>
                      <span className='mx-2'>{pageNum} of {totalPages}</span>
                      <button type="button" className='btn btn-primary' onClick={pdfNextPage}
                        disabled={(pageNum === totalPages) ? true : false}>›</button>
                    </div>
                  }
                </>
              }
            </div>
          </Modal.Body>
        </Modal>

        {/* confirmation modal  */}
        <Modal show={confirmationModal} onHide={() => setConfirmationModal(false)}
          id='selectCandidateModal'>
          <Modal.Body>
            <h5 className='text-center'>Are you sure ?</h5>
          </Modal.Body>
          <Modal.Footer className='flex-wrap justify-content-center'>
            <div className="">
            <button className='btn btn-primary mx-1' type="button" onClick={handleConfirmationOk} >
                YES
              </button>
              <button className='btn btn-secondary mx-1' onClick={() => setConfirmationModal(false)}>
                Cancel
              </button>
             
            </div>
          </Modal.Footer>
        </Modal>
        <DialogAlert
          open={open}
          handleClose={handleDailogClose}
          message={dialogMessage}
          title="Warning"
        />
      </Container>
    </div>
  )
}

export default EmpAccount

