import React, { useEffect, useState,useContext } from 'react';
import axios from "axios";
import Datetime from 'react-datetime';

import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useNavigate } from 'react-router-dom';

import { MdEdit } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';

import { CustomModal } from '../../../../components/customModal';
import getBaseUrl from "../../../../hooks/http";
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useSocketContext } from '../../../../hooks/useSocketContext';
import { CountContext } from '../../../../context/CountContext';
import DialogAlert from '../../../../components/customModal/customAlertModal';
import './interview.css';




const Interview = () => {

   const navigate = useNavigate();

   const { user, dispatch } = useAuthContext();
   const socket = useSocketContext();
   const { updateInterviewCount} = useContext(CountContext);

   const [candidate, setcandidate] = useState(null)
   const [interviews, setInterviews] = useState([])
   const [interviewHistory, setInterviewsHistory] = useState([])
   const [dataType, setDataType] = useState('currentInterview')
   const [empId, setEmpId] = useState('')
   const [interviewAction, setInterviewAction] = useState('')
   const [formErrors, setFormErrors] = useState({});
   const [classActiveName, setClassName] = useState('active');
   const [minTime, setMinTime] = useState(null);
   const [verificationCount, setVerificationCount] = useState(1);
   const [open, setOpen] = React.useState(false);
   const [dialogMessage, setDialogMessage] = useState('');
  

   const CandidateIdUrl = localStorage.getItem('CandidateId');

   //intervew count 
   const [offerCount, setOfferCount] = useState(0);
   const [upCommingInCount, setUpCommingInCount] = useState(0);
   const [interviewAttended, setIntervewAttende] = useState(0);


   //update User data
   const userUpdate = (updatedData) => {
      const userInfo = {
         ...JSON.parse(localStorage.getItem('user')),
         ...updatedData
      };
      /* update the auth context */
      dispatch({ type: 'UPDATE-USER', payload: userInfo })
   }

   const handleCndInterviewStatusUpdate = data => {
      if (user.candidateId === data.cndId) {
         getInterviews(data.status);
      }
   }
  
   useEffect(() => {
      window.scrollTo(0,0);
      getCandidate();
      const id = localStorage.getItem("empId");
      const arrayOfIdAction = id.split("_");
      setEmpId(arrayOfIdAction[0]);
      setInterviewAction(arrayOfIdAction[1])
      const currentDateTime = new Date();
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      setMinTime(currentDateTime);

         if (dataType === 'currentInterview') getInterviews();
         else getInterviewsHistory();
      
      //socket io stuff
      socket.on('cndInterviewStatusUpdate', handleCndInterviewStatusUpdate);

      return () => {
         socket.off('cndInterviewStatusUpdate', handleCndInterviewStatusUpdate);
      };

   }, [dataType])

   //realtime status update
   const handleStatusUpdate = (empId, status) => {
      socket.emit('candidateUpdate', { empId: empId, status: status });
   };

   //deleteConfirmationModal ----------------------------------------------
   const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
      show: false,
      index: ''
   });

   const handleAlertClose = () => {
      setOpen(false);
   };

   const handleClose = (i) => {
      //set reasonforcancel value null
      interviews[i]['reasonforcancel'] = '';
      //hide modal
      setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false });
      //set error null
      setFormErrors("")
   };



   //fetch candidate data -----------------------------
   const getCandidate = async () => {

      try {
         const user = JSON.parse(localStorage.getItem('user'))
         if (user !== null) {
            const { token } = user;
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };
            
            const response = await axios.get(`${getBaseUrl}/candidates/personalDetails/${token}`, config);

            if (response.status === 200) {
               const { photo, name, birthday, nationality, address,verificationCounter } = response.data.personalDetail;
              // setVerificationCount(verificationCounter);
            //   window.scrollTo({ top: 0, behavior: 'smooth' });
               const profilePhoto = photo ? photo.data : '/uploads/userIcon.png';
               const dob = new Date(birthday.data);

               let formatted_dob = dob.toLocaleString('en-GB').slice(0, 10);
               //Update User context Data
               userUpdate({ photo: photo })
               //------------
               setcandidate({
                  photo: profilePhoto,
                  name: name.data,
                  birthday: formatted_dob,
                  nationality: nationality.data,
                  address: address.data,
                  verificationCount:verificationCounter,
               })
            }
         }
      } catch (error) {
         console.log(error);
      }

   }
   //fetch interviews data -------------------------------
   const getInterviews = async (action = "") => {
      try {
         const user = JSON.parse(localStorage.getItem('user'))
         // if (user !== null) {
         
            let config = {
               headers: {
                  Authorization: `Bearer ${user.token}`,
               },
            };
            const response = await axios.get(`${getBaseUrl}/interviews/candidate`, config);
            console.log(response);

            if(response.data.personalDetailsData!==null){
               setVerificationCount(response.data.personalDetailsData.verificationCounter);
             }else{
               setVerificationCount(null);
             }
            
            
           updateInterviewCount(response.data.interviewsData.length);
            if (response.status === 200) {
               const data = await response.data.interviewsData;
               setOfferCount(response.data.offerCount)
               setUpCommingInCount(data.length);
               setIntervewAttende(response.data.interviewHistory);
               const empData = data.map(dt => {
                  const InterDate = new Date(dt.Interview_Date_Time);
                  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  const newDateTime = InterDate.toLocaleString('en-US', { timeZone: `${timezone}` })
                  const Interview_Date_Time = (newDateTime) ? new Date(newDateTime) : '';


                  return {
                     id: dt._id,
                     date: Interview_Date_Time,
                     reschedule: {
                        date: new Date(Interview_Date_Time),
                        isShow: false
                     },
                     status: dt.Interview_Status,
                     link: dt.Interview_Link,
                     emp_id: dt.Employer_UserID,
                     EmpTempId: dt.Employer_TempId,
                     EmpTempName: dt.empDetails[0]?.name ?? 'Unknown',
                     candidate_id: localStorage.getItem('CandidateId'),
                  }
               });

               setInterviews(empData)
               setInterviewsHistory([])


               setTimeout(() => {
                  const lc_EmpId = localStorage.getItem("empId");
                  const arrayOfIdAction = lc_EmpId.split("_");
                  // const stateOfModel = (arrayOfIdAction[0] === dt.Employer_UserID) ? true : false;
                  if (arrayOfIdAction[1] === "Reschedule" && action === "") {

                     //window.scrollTo(0, 300);
                     const list = empData;
                     const icon = document.querySelectorAll('.icon1')
                     for (let i = 0; i < list.length; i++) {

                        const isShow = (arrayOfIdAction[0] === list[i].emp_id) ? true : false;

                        if (icon.length > 0 && isShow === true) {
                           let bodyRect = document.body.getBoundingClientRect();
                           let btnRect = icon[i].getBoundingClientRect();
                           let btnOffsetTop = btnRect.top - bodyRect.top;
                           let btnOffsetLeft = btnRect.left - bodyRect.left;
                           let scroll = document.documentElement.scrollTop || document.body.scrollTop;
                           let top = (btnOffsetTop) - scroll;
                           window.scrollTo(0, top);

                           //set reschedule popup -----------------
                           list[i]['reschedule']['isShow'] = isShow;
                           setInterviews(list)
                        }
                     }
                  }
               }, 1000);

            }
         // }

      } catch (error) {
         console.log(error)
      }
   }

   // get Interviews History data ----------------------
   const getInterviewsHistory = async () => {
      try {
         const user = JSON.parse(localStorage.getItem('user'))
         if (user !== null) {
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.get(`${getBaseUrl}/interviews/history/candidate/`, config);
            if (res.status === 200) {
               const interviewHistoryData = await res.data.interviewHistoryData;
               const data = interviewHistoryData.map(ihd => {

                  const InterDate = new Date(ihd?.Interview_Date_Time);
                  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  const newDateTime = InterDate.toLocaleString('en-US', { timeZone: `${timezone}` })
                  const Interview_Date_Time = (newDateTime) ? new Date(newDateTime) : '';

                  return {
                     EmployerTempName: ihd.empDetails[0].name ?? 'Unknown',
                     date: new Date(Interview_Date_Time),
                     interviewStatus: ihd?.Interview_Status,
                     reasonForCancel: ihd?.Reason_For_Cancel || '',
                  }
               });
               setInterviews([]);
               setInterviewsHistory(data)
            }
         }

      } catch (error) {
         console.log(error)
      }
   }


   //handle Rescheduled Date Change
   const handleRescheduledDateChange = (date, i) => {
      const currentDateTime = new Date();
      if (new Date(date).toDateString() === currentDateTime.toDateString()) {
         // If a future date is selected, clear the excluded times
         //setExcludedTimes([]);
         currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
         setMinTime(currentDateTime);

      } else {
         // If the current date or a past date is selected, update the excluded times
         const newd = new Date(
            currentDateTime.getFullYear(),
            currentDateTime.getMonth(),
            currentDateTime.getDate(),
            0, // Hours (midnight)
            0  // Minutes
         );
         setMinTime(newd);
      }

      const list = [...interviews];
      list[i]['reschedule']['date'] = new Date(date);
      list[i]['reschedule']['error'] = false;
      list[i]['reschedule']['error2'] = false;
      setInterviews(list)
   }
   // get position
   const position = (e, sl = false) => {
      const target = (sl) ? e : e.target;
      let bodyRect = document.body.getBoundingClientRect();
      let btnRect = target.getBoundingClientRect();
      //reschedule calendar popup width and height
      const popupWidth = 320;
      const popupHeight = 430;

      const tableWrapper = document.querySelector(".box");
      const tableWrapperReact = tableWrapper.getBoundingClientRect();

      let btnOffsetTop = btnRect.top - bodyRect.top;
      let btnOffsetLeft = btnRect.left - bodyRect.left;

      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      let top = (btnOffsetTop - popupHeight - 10) - scroll;
      let left = (btnOffsetLeft + (target.offsetWidth / 2) - (popupWidth / 2));

      if (tableWrapperReact.width < btnRect.left) left = btnRect.left - popupWidth;
      if (btnRect.top < popupHeight) top = (btnRect.top / 2); left = btnRect.left - popupWidth;
      if (btnRect.left < popupWidth + 2) left = 'calc(50% - 160px)';

      return { top, left };
   }
   // openRescheduledDatePickier
   const openRescheduledDatePickier = (e, i) => {

      const { top, left } = position(e);

      const list = [...interviews];
      const scheduleDate = list[i]['reschedule']['date'];
      const currentDateTime = new Date();
      if (new Date(scheduleDate).toDateString() === currentDateTime.toDateString()) {
         // If a future date is selected, clear the excluded times
         //setExcludedTimes([]);
         currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
         setMinTime(currentDateTime);

      } else {
         // If the current date or a past date is selected, update the excluded times
         const newd = new Date(
            currentDateTime.getFullYear(),
            currentDateTime.getMonth(),
            currentDateTime.getDate(),
            0, // Hours (midnight)
            0  // Minutes
         );
         setMinTime(newd);

      }
      for (let index = 0; index < list.length; index++) {
         if (index !== i) {
            list[index]['reschedule']['isShow'] = false;
            list[i]['reschedule']['error'] = false;
            list[i]['reschedule']['error2'] = false;
         }
      }
      list[i]['reschedule']['isShow'] = (list[i].reschedule.isShow) ? false : true;
      list[i]['reschedule']['top'] = top;
      list[i]['reschedule']['left'] = left;
      list[i]['reschedule']['date'] = list[i]['date'];
      setInterviews(list)
   }
   // closeRescheduledDatePickier
   const closeRescheduledDatePickier = (i) => {
      const list = [...interviews];
      list[i]['reschedule']['isShow'] = false;
      list[i]['reschedule']['error'] = false;
      list[i]['reschedule']['error2'] = false;
      const lc_id = localStorage.getItem('empId');
      // const arrayOfIdAction = lc_id.split("_");
      localStorage.setItem('empId', null);
      setClassName('false');
      setInterviews(list);
   }
   // handleRescheduleClick
   const handleRescheduleClick = (i) => {
      const list = [...interviews];

      var preDate = new Date(list[i].date);
      var newDate = new Date(list[i].reschedule.date);
      var currentDate = new Date();
      const timeDifference = newDate - currentDate;
      const timeDifference2 = Math.round((timeDifference / 1000) / 60);

      if (preDate.getTime() === newDate.getTime()) {
         list[i]['reschedule']['error'] = true;
         setInterviews(list);
      } else if (timeDifference2 <= 30) {
         list[i]['reschedule']['error2'] = true;
         setInterviews(list);
      } else {
         list[i]['reschedule']['isShow'] = false;
         setInterviews(list);
         //call WA msg func
         sendWhatsapp(i);
      }
   }

   //Code added by Namrata
   const sendWhatsapp = async (index) => {
      try {
         const list = [...interviews];
         const listData = list[index];

         const res = await axios.post(`${getBaseUrl}/candidates/sendWhatsappMsgtoEmp/`, listData)

         if (res.status === 200) {
            getInterviews("Reschedule");
            //closeRescheduledDatePickier
            closeRescheduledDatePickier(index)
            handleStatusUpdate(listData.emp_id, 'Reschedule')
         }

      } catch (error) {
         console.log(error);
      }
   };



   //handle confirm Interview click
   const confirmInterview = async (index) => {
      try {
         const listData = interviews[index];

         const res = await axios.post(`${getBaseUrl}/interviews/confirmByCandidate`, listData);

         if (res.status === 200) {
            getInterviews('confirm');
            handleStatusUpdate(listData.emp_id, 'confirm')
         }
      } catch (error) {
         if (error.response && error.response.status === 400) {
            setOpen(true);
            setDialogMessage(error.response.data.message)
          } else {

          }
      }
   }

   //handle cancle interview click -------
   const handleCancelInterview = (i) => {
      //set reasonforcancel value null
      interviews[i]['reasonforcancel'] = '';
      //show modal
      setDeleteConfirmationModal({
         ...deleteConfirmationModal,
         show: true, index: i
      });
   }


   const handleReasonOfCancal = (cancelreason, i) => {
      setFormErrors("");

      const list = [...interviews];
      list[i]['reasonforcancel'] = cancelreason.target.value;
      setInterviews(list)
   }

   //handle cancle interview click
   const cancelInterview = async (index) => {
      try {
         const listData = interviews[index];
         if (!!listData.reasonforcancel) { //code by mohsin
            const res = await axios.post(`${getBaseUrl}/interviews/cancelByCandidate`, listData);

            if (res.status === 200) {
               getInterviews();
               //hide modal
               setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false });
               handleStatusUpdate(listData.emp_id, 'cancel')
               try {
                  let config = {
                     headers: { Authorization: `Bearer ${user.token}`, },
                  };
                  const getInterviewsCount=await axios.get(`${getBaseUrl}/interviews/CandidategetCount`, config)
                  updateInterviewCount(getInterviewsCount.data.interviewCount)
               }catch(e){

               } 
            }
         } else {
            let errors = {};
            errors.msg = "This is required field";
            setFormErrors(errors);
         }

      } catch (error) {
         console.log(error);
      }
   }

   //window scroll change calendar position
   window.addEventListener('scroll', () => {
      const list = [...interviews];
      const icon = document.querySelectorAll('.icon1')
      for (let i = 0; i < list.length; i++) {
         const isShow = list[i].reschedule.isShow;
         if (icon.length > 0 && isShow) {
            icon.forEach(async (element, i) => {
               const { top, left } = await position(element, true);
               list[i]['reschedule']['top'] = top;
               list[i]['reschedule']['left'] = left;
               setInterviews(list);
            });
         }
      }
   });

   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };





   const joinMeeting = (date, status, link) => {
      const ACTIVE_STATUSES = ['Candidate Confirmed for Interview Meeting', 'Reschedule - Employer Confirmed Interview Meeting', 'Reschedule - Candidate Confirmed Interview Meeting'];
      const isConfirmed = ACTIVE_STATUSES.includes(status);
      // console.log(row.original.date);
      const currentTime = new Date();
      const scheduleDate = new Date(date);
      const dateDiff = currentTime - scheduleDate;
      const timeDiff = Math.round((dateDiff / 1000) / 60);
      // console.log("timeDiff : ", timeDiff);
      //display join meeting button before 30 minute and after 3o minutes of scheduled time
      return <>
         {
            (isConfirmed && (timeDiff > -15) && (timeDiff < 30)) ? <a className="btn btn-sm" href={`https://teams.microsoft.com/l/meetup-join/${link}`} role="button" target="_blank" style={{ background: '#95D03A', color: '#fff' }} rel="noopener noreferrer">Join Meeting</a>
               : null
         }
      </>
   }



   return (
      <div>
         {
             (verificationCount===null) && 
             <div className={`Banner`}>
             <div className='mt-3'>
                <p>You need to complete Personal Details section 1<sup>st</sup> to Start. <span onClick={(() => navigate('/candidate/profile/personal-details'))}>Click Here</span> to goto Personals Deatils.</p>
             </div>
           </div> 
         }
         {
           (verificationCount===0) && 
           <div className={`Banner`}>
           <div className='mt-3'>
              <p>Personal details are pending for approval. Please contact Admin at <a href={`mailto:support@helperwish.com`} style={{color:'white'}}>support@helperwish.com</a> for any questions</p>
           </div>
         </div>  
         }
         <Container className='candidate_interview_container'>
          
            <Row className='mt-5 justify-content-center'>
               <Col sm={6} md={4} className='mb-3'>
                  <div className="candidate_interview">
                     <div className="candidate_interview-photo mx-auto">
                        <img className='w-100 h-100' src={candidate?.photo ? `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate?.photo}` : '/uploads/userIcon.png'}
                           onError={imageOnError} alt="profile" />
                     </div>
                  </div>
               </Col>
               <Col sm={6} md={5} className='text-center text-sm-start'>
                  <p className='fs-4 fw-bold'>
                     {candidate?.name ? candidate.name : 'Candidate NAME'}
                     <MdEdit className='fs-2 ms-3 cursor-pointer' onClick={(() => navigate('/candidate/profile/my-account'))} />
                  </p>
                  <p className='fs-5'>
                     Birth Date: {candidate?.name ? candidate.birthday : 'dd/mm/yyyy'}
                  </p>
                  <p className='fs-5'>
                     Nationality: {candidate?.nationality ? candidate.nationality : '-'}
                  </p>
                  <p className='fs-5'>
                     Address: {candidate?.address ? candidate.address : '-'}
                  </p>
               </Col>
            </Row>

            <Row className='mt-5 justify-content-center'>
               <Col sm={6} lg={4} xl={3} className='mb-3'>
                  <div className="candidate_interview-card">
                     <p>Interviews Attended</p>
                     <span>{interviewAttended}</span>
                  </div>
               </Col>
               <Col sm={6} lg={4} xl={3} className='mb-3'>
                  <div className="candidate_interview-card">
                     <p className='text'>Upcoming Interviews</p>
                     <span className='number'>{upCommingInCount}</span>
                  </div>
               </Col>
               <Col sm={6} lg={4} xl={3} className='mb-3'>
                  <div className="candidate_interview-card">
                     <p>Offer</p>
                     <span>{offerCount}</span>
                  </div>
               </Col>

            </Row>

            <Row className='my-5'>
               <h1 className='text-center text-blue mb-4'>Interviews List</h1>
               <Col sm={12}>
                  <div className="text-center pb-5">
                     <div className="form-check form-check-inline mb-3 mb-sm-0">
                        <input className="d-none" type="radio" name="interview" id="currentInterview" value="currentInterview"
                           checked={(dataType === 'currentInterview') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-pink" htmlFor="currentInterview">Current Interviews</label>
                     </div>
                     <div className="form-check form-check-inline">
                        <input className="d-none" type="radio" name="interview" id="interviewHistory" value="interviewHistory"
                           checked={(dataType === 'interviewHistory') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-pink" htmlFor="interviewHistory">Interview History</label>
                     </div>
                  </div>
               </Col>
               <Col>
                  <div className="table-responsive interview-list candidate_interview-list box">
                     {
                        (dataType === 'currentInterview') &&
                        <Table className="table table-striped m-0">
                           <Thead className='table-dark text-center'>
                              <Tr>
                                 <Th className='py-3 hash' scope="col">#</Th>
                                 <Th></Th>
                                 {/* <Th className='py-3' scope="col">Employer Id</Th> */}
                                 <Th className='py-3' scope="col">Employer</Th>
                                 <Th className='py-3' scope="col">Interview on</Th>
                                 <Th className='py-3' scope="col">Status</Th>
                                 <Th className='py-3' scope="col">Action</Th>
                                 {/* <Th className='py-3' scope="col">Temp Action</Th> */}
                              </Tr>
                           </Thead>
                           <Tbody className='text-center'>
                              {interviews.map((interview, index) =>
                                 <Tr key={index} className={
                                    `${empId === interview.emp_id && classActiveName}
                                  ${interview.reschedule.isShow && 'active'}`
                                 }
                                 >
                                    <Td className='px-3 srNo'>{index + 1}</Td>
                                    <Td className='profile-wrapper'>
                                       <div className="profile-picture">
                                          {interview.EmpTempName.charAt(0).toUpperCase()}
                                       </div>
                                    </Td>
                                    {/* <Td>{interview.EmpTempId}</Td> */}
                                    <Td> {interview?.EmpTempName?.length > 20 ? `${interview?.EmpTempName?.slice(0, 20)}...` : interview?.EmpTempName}</Td>
                                    <Td className='interview-on'>
                                       {
                                          interview.date.toLocaleString('en-GB', {
                                             year: 'numeric', month: '2-digit', day: '2-digit',
                                             hour: '2-digit', minute: '2-digit', hour12: true
                                          }).replace(/,/g, ' on ').replace(/00:/g, '12:')
                                       }
                                       <br />
                                       {
                                          `${interview.date}`.substring(
                                             `${interview.date}`.indexOf("("),
                                             `${interview.date}`.lastIndexOf(")") + 1
                                          )
                                       }
                                       <br />
                                       {
                                          joinMeeting(interview.date, interview.status, interview.link)
                                       }

                                    </Td>
                                    <Td className='status'>{interview.status}</Td>
                                    <Td className='position-relative'>

                                       <div id="action" className='d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-evenly'>
                                          <div className='icon icon1' onClick={(event) => openRescheduledDatePickier(event, index)}>
                                             <img src="/images/reschedule.png" alt=""  />
                                             <span className='text'>RESCHEDULE</span>
                                          </div>

                                          <div className={`icon icon2 mx-0 mx-sm-3 ${(interview?.status === 'Pending Candidate Confirmation for Interview Meeting' || interview?.status === 'Reschedule - Pending Candidate Confirmation for Interview Meeting') ? "NoStyle" : "pe-none"}`} onClick={() => confirmInterview(index)}>
                                             <img src="/images/confirm.png" alt="" 
                                                className={`${(interview?.status === 'Pending Candidate Confirmation for Interview Meeting' || interview?.status === 'Reschedule - Pending Candidate Confirmation for Interview Meeting') ? "NoStyle" : "disabled-image"}`}
                                             />
                                             <span className={`${(interview?.status === 'Pending Candidate Confirmation for Interview Meeting' || interview?.status === 'Reschedule - Pending Candidate Confirmation for Interview Meeting') ? "text" : "text-secondary"}`}>CONFIRM</span>
                                          </div>
                                          <div className='icon icon3 mx-auto mx-sm-0' onClick={() => handleCancelInterview(index)}>
                                             <img src="/images/cancel.png"  alt="" />
                                             <span className='text'>CANCEL</span>
                                          </div>
                                       </div>
                                    </Td>
                                    {/* <Td>
                                    <button className="btn btn-primary" onClick={() => sendWhatsapp(index)}>Send Interview Template</button>
                                 </Td> */}
                                 </Tr>
                              )}

                              {
                                 (interviews.length === 0) && <Tr>
                                    <Th colSpan='6'>Record Not Found.</Th>
                                 </Tr>
                              }
                           </Tbody>
                        </Table>
                     }
                     {/* interview history data  */}
                     {
                        (dataType === 'interviewHistory') &&
                        <Table className="table table-striped m-0">
                           <Thead className='table-dark text-center'>
                              <Tr>
                                 <Th className='py-3 hash' scope="col">#</Th>
                                 <Th></Th>
                                 <Th className='py-3' scope="col">Employer</Th>
                                 <Th className='py-3' scope="col">Interview Process Ended on</Th>
                                 <Th className='py-3' scope="col">Status</Th>
                                 <Th className='py-3' scope="col">Remarks</Th>

                              </Tr>
                           </Thead>
                           <Tbody className="text-center">
                              {interviewHistory.map((interview, index) => (
                                 <Tr key={index}>
                                    <Td className='px-3 srNo'>{index + 1}</Td>
                                    <Td className='profile-wrapper'>
                                       <div className="profile-picture">
                                          {interview.EmployerTempName.charAt(0).toUpperCase()}
                                       </div>
                                    </Td>
                                    <Td>{interview.EmployerTempName}</Td>
                                    <Td className="interview-on">
                                       {
                                          interview.date.toLocaleString('en-GB', {
                                             year: 'numeric', month: '2-digit', day: '2-digit',
                                             hour: '2-digit', minute: '2-digit', hour12: true
                                          }).replace(/,/g, ' on ').replace(/00:/g, '12:')
                                       }
                                       <br />
                                       {
                                          `${interview.date}`.substring(
                                             `${interview.date}`.indexOf("("),
                                             `${interview.date}`.lastIndexOf(")") + 1
                                          )
                                       }
                                    </Td>
                                    <Td className="status">{interview.interviewStatus}</Td>
                                    <Td className="pb-4 pb-sm-0">{interview.reasonForCancel}</Td>
                                 </Tr>
                              ))}

                              {
                                 (interviewHistory.length === 0) && <Tr>
                                    <Th colSpan='6'>Record Not Found.</Th>
                                 </Tr>
                              }

                           </Tbody>
                        </Table>
                     }
                  </div>
               </Col>
            </Row>
         </Container>
         {/* reschedule-popup */}
         {interviews.map((interview, index) =>
            <CustomModal key={index} isOpen={interview.reschedule.isShow} onClose={() => closeRescheduledDatePickier(index)}>
               <div className={`reschedule-popup`}
                  style={{ top: interview.reschedule.top, left: interview.reschedule.left }}
               >

                  <div className='reschedule-popup-date bg-blue text-white py-2 text-center'>
                     Current Interview:<br />
                     {interview.reschedule.date.toLocaleString('en-GB', {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                        hour: '2-digit', minute: '2-digit', hour12: true
                     }).replace(/00:/g, '12:').replace(/,/g, ' at ')
                     }
                     <br />
                     {
                        `${interview.reschedule.date}`.substring(
                           `${interview.reschedule.date}`.indexOf("("),
                           `${interview.reschedule.date}`.lastIndexOf(")") + 1
                        )
                     }
                  </div>
                  {/* <DatePicker
                     className='w-100 candidate_interview-DatePicker'
                     placeholderText="Select"
                     dateFormat="dd/MM/yyyy h:mm aa"
                     //showTimeInput
                     calendarStartDay={1}
                     // showMonthDropdown
                     // showYearDropdown
                     //dropdownMode="select"
                     showTimeSelect
                     selected={interview.reschedule.date}
                     onChange={(date) => handleRescheduledDateChange(date, index)}
                     minDate={new Date()}
                     minTime={minTime} //set current time to disable past time
                     maxTime={setHours(setMinutes(new Date(), 59), 23)} //this set the max time (i.e. 23:59)
                     timeIntervals={10}
                     inline
                  /> */}

                  <Datetime
                     className=''
                     value={interview.reschedule.date}
                     onChange={(date) => handleRescheduledDateChange(date, index)}
                     dateFormat="DD/MM/YYYY"
                     timeFormat="hh:mm A" // Set time format to AM/PM
                     isValidDate={(current) => current.isAfter(new Date()) || current.isSameOrAfter(new Date().setDate(new Date().getDate() - 1))} // Set the minimum date
                     input={false}
                  />

                  {interview.reschedule.error &&
                     <div className='bg-white text-danger text-center fs-6 mb-2'>
                       Please select Date and Time
                     </div>
                  }
                  {interview.reschedule.error2 &&
                     <div className='bg-white text-danger fs-6 mb-2 text-center'>Time selected should be <br/> later than Current Time</div>
                  }
                  <div className="d-flex justify-content-center mt-2">
                     <button className="btn btn-primary btn-sm me-1"
                        onClick={() => handleRescheduleClick(index)}>RESCHEDULE
                     </button>
                     <button className='btn btn-secondary btn-sm ms-1'
                        onClick={() => closeRescheduledDatePickier(index)}>
                        cancel
                     </button>
                  </div>
               </div>
            </CustomModal>
         )}


         {/* delete confoirmation modal  */}
         <Modal show={deleteConfirmationModal.show} onHide={() => handleClose(deleteConfirmationModal.index)}
            id='deleteConfirmationModal' className='confirmationBox'>
            <Modal.Header className='position-relative'>
               <Modal.Title>Attention Required</Modal.Title>

               <AiOutlineClose onClick={() => handleClose(deleteConfirmationModal.index)}
                  className='icon close-icon position-absolute' />

            </Modal.Header>
            <Modal.Body>
               <Form>
                  <Form.Group
                     className="mb-3"
                     controlId="exampleForm.ControlTextarea1"
                  >
                     <Form.Label>Reason for Cancellation?</Form.Label>
                     <Form.Control as="textarea" rows={3} name="cancelreason"
                        value={interviews[deleteConfirmationModal.index]?.reasonforcancel}
                        onChange={(cancelreason) => handleReasonOfCancal(cancelreason, deleteConfirmationModal.index)}
                        required />
                     <p></p>
                  </Form.Group>
                  {formErrors.msg && <div className='text-danger'>{formErrors.msg}</div>}
               </Form>
               <h5>Are you sure you want to cancel interview?</h5>
               <div className='d-flex justify-content-end gap-10 mt-4'>
                  <Button variant="outline-secondary" onClick={() => handleClose(deleteConfirmationModal.index)}>
                     NO
                  </Button>
                  <Button variant="danger" type="button" onClick={() => cancelInterview(deleteConfirmationModal.index)} >
                     YES
                  </Button>
               </div>
            </Modal.Body>
         </Modal>
         <DialogAlert
                        open={open}
                        handleClose={handleAlertClose}
                        message={dialogMessage}
                        title="Warning"
                     />

      </div>
   )
}

export default Interview