import { useState, useEffect ,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";
import { io } from "socket.io-client";
import { useLogout } from '../../../hooks/useLogout';

import {CountContext} from '../../../context/CountContext.js'


export const useLogin = () => {

   const naviagte = useNavigate()
   const { logout } = useLogout()

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const { dispatch } = useAuthContext();
   const { getInterviewDataCount } = useContext(CountContext);




   const handleStatusUpdate = (sessionId, EmpId) => {
      const url = `${getBaseUrl}`.replace(/\/api/g, '')
      const socket = io.connect(url);
      socket.emit('employerLogin', { sessionId: sessionId, userId: EmpId });
      console.log('employerLogin');
   };

   const login = async (dialingCode, phoneNumber, password, urlCandidateId) => {
      setError(null)
      setIsLoading(true)

      const regex = /^\d+$/;
      let inputValue = '';

      if (regex.test(phoneNumber)) {
         inputValue = `${dialingCode}${phoneNumber}`.replace(/[+()]/g, "");
      } else {
         inputValue = phoneNumber;
      }
      var browserName = navigator.userAgent;
      console.log(browserName);

      const body = { inputValue, password }
      axios.post(`${getBaseUrl}/employer/login`, body)
         .then((res) => {
            if (res.status === 200) {
               /* save the user to local storsge */
               console.log(res);
               localStorage.setItem('user', JSON.stringify(res.data))
               localStorage.setItem('empId', res.data.EmpId)
               localStorage.setItem('candId', urlCandidateId);
               localStorage.setItem('First_time_Employer', res.data.First_time_Employer);
               sessionStorage.setItem("ssid", res.data.sessionId);
               
               getInterviewDataCount();
               /* update the auth context */
               dispatch({ type: 'LOGIN', payload: res.data })
               if (res.data.Is_AgreementSign == "No") {
                  naviagte('/employer/profile/agreement');
               } else if(res.data.First_time_Employer===undefined && res.data.Is_AgreementSign == "Yes")
               {
                  naviagte('/employer/profile/MOMDeclaration');
               }
               else if (urlCandidateId !== ''  && urlCandidateId !== undefined && urlCandidateId.includes("Interview")) {
                  naviagte('/employer/profile/interview');
               } else if (urlCandidateId !== '' && urlCandidateId !== undefined && urlCandidateId.includes("Offers")){
                  naviagte('/employer/profile/offers');
               }else {
                  naviagte('/employer/profile/home')
               }


               handleStatusUpdate(res.data.sessionId, res.data.EmpId);

               setIsLoading(true)
            } else {
               setIsLoading(false)
               setError(res.data.error)
            }
         })
         .catch((error) => {
            // handle error
            setIsLoading(false)
            setError(error.response.data.error);
         })
   }

   return { login, isLoading, error, setError }
}